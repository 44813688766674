<template>
  <div class="article-item p-10 mb-20" :class="{border: border}">
    <div class="fs-18 p-5" @click="toArticle">
      <strong class="m-c-p">{{ article.title }}</strong>
    </div>
    <div class="fs-12 p-5 mb-10" style="color:#666" @click="toArticle">
      <span class="m-c-p text-ellipsis" style="width: 100rem">简介：{{ article.synopsis ? article.synopsis : "暂无简介" }}</span>
    </div>
    <div class="d-flex jc-between ai-center" style="color:#666">
      <div class="fs-12 p-5 d-flex ai-center">
        <div class="mr-10" style="width: 2.5rem; height: 2.5rem; overflow:hidden; border-radius:50%;">
          <img style="width: 100%" :src="touxiang" alt="">
        </div>
        <div class="mr-30">{{ article.userId ? article.userId.username : '系统管理员' }}</div>
        <div class="mr-30">更新日期：{{ updateTime }}</div>
        <div class="mr-30">分类：{{ category }}</div>
        <div class="mr-30">浏览次数： {{ article.views ? article.views : 0 }}</div>
        <div class="mr-30">评论： {{ article.commentsCount ? article.commentsCount : 0 }}</div>
        <div>点赞：{{ article.like ? article.like.length : 0}}</div>
      </div>
      <div>
        <div v-if="edit == true" class="mr-20 d-flex">
          <span @click="editArt" class="edit-button m-c-p  mr-20">编辑</span>
          <span @click="removeArt" class="edit-button m-c-p">删除</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:['article', 'border', 'edit'],
  inject:['reload'],
  methods:{
    toArticle(){
      this.$router.push(`/articleInfo/${this.article._id}`)
    },
    utc2beijing(utc_datetime) {
    // 转为正常的时间格式 年-月-日 时:分:秒
    var T_pos = utc_datetime.indexOf('T');
    var Z_pos = utc_datetime.indexOf('Z');
    var year_month_day = utc_datetime.substr(0,T_pos);
    var hour_minute_second = utc_datetime.substr(T_pos+1,Z_pos-T_pos-1);
    var new_datetime = year_month_day+" "+hour_minute_second; // 2017-03-31 08:02:06

    // 处理成为时间戳
    timestamp = new Date(Date.parse(new_datetime));
    timestamp = timestamp.getTime();
    timestamp = timestamp/1000;

    // 增加8个小时，北京时间比utc时间多八个时区
    var timestamp = timestamp+8*60*60;

    // 时间戳转为时间
    var beijing_datetime = new Date(parseInt(timestamp) * 1000).toLocaleString().replace(/年|月/g, "-").replace(/日/g, " ");
    return beijing_datetime; // 2017-03-31 16:02:06
    },
    editArt(){
      this.$router.push(`/publishArticle/${this.article._id}`)
    },
    removeArt(){
      this.$confirm('此操作将永久删除该文章(评论、回复), 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.delete(`/deleteArticle/${this.article._id}`).then(result => {
          console.log(result.data)
          if(result.data.message == 'success'){
            this.reload()
            this.$message({type: 'success', message: '删除成功！'})
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });          
      });
    }
  },
  computed:{
    updateTime(){
      if(this.article.updated){
        return this.utc2beijing(this.article.updated)
      }
    },
    category(){
      return this.article.categories.map(item => item.name).join('/')
    },
    touxiang(){
      if(this.article.userId){
        return this.article.userId.touxiang
      }else{
        return 'http://www.sunwenliang.com/images/touxiang/touxiang1.png'
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/css/variables';
    .article-item{
        background: white;
        border-radius: 0.5rem;
        &.border{
          background: #fafafa;
          border: 1px solid #eee;
        }
        .edit-button:hover{
          color: map-get($colors, 'purple');
        }
    }
    .article-item:hover{
      box-shadow: 0 0 1rem rgba(0,0,0,0.12);
    }
</style>