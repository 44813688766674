<template>
  <div class="background-color">
    <el-scrollbar style="height:100%" ref="scroll">
      <div class="wrap d-flex jc-between">
        <div class="article-nav">
          <div class="issue-article fs-15 p-10 m-c-p" @click="publishArticle">
            <strong>发布文章</strong>
          </div>
          <div class="mt-10 p-20 d-flex jc-around fs-15" style="background: white; border-radius: 0.5rem">
            <div class="d-flex flex-column ai-center">
              <span class="mb-5">{{count.articles}}</span>
              <span>文章</span>
            </div>
            <div class="d-flex flex-column ai-center">
              <span class="mb-5">{{ count.comments }}</span>
              <span>回复</span>
            </div>
            <div class="d-flex flex-column ai-center">
              <span class="mb-5">{{ count.users }}</span>
              <span>用户</span>
            </div>
          </div>
          <div class="nav-list p-15 fs-13">
            <div style="border-bottom: 1px solid #eee" class="pb-10 mb-5">
              <i class="iconfont icon-19"></i><span class="ml-5">文章分类</span>
            </div>
            <div class="py-5 m-c-p" @click="articleSort('all', count.articles)">
              <div class="px-10 py-5  d-flex jc-between" :class="{active: isActive == 'all'}">
                <span>全部分类</span>
                <div class="d-flex">
                  <span>{{count.articles}}</span>
                  <span class="iconfont icon-gengduo" style="margin-top:0.1rem;"></span>
                </div>
              </div>
            </div>
            <div class="py-5 m-c-p" @click="articleSort(item._id, count[item.name])" v-for="item in navList" :key="item._id">
              <div class="px-10 py-5  d-flex jc-between" :class="{active: isActive == item._id}">
                <span>{{item.name}}</span>
                <div class="d-flex">
                  <span>{{count[item.name]}}</span>
                  <span class="iconfont icon-gengduo" style="margin-top:0.1rem;"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="article-list">
          <article-title v-for="item in articleList" :key="item._id" :article="item"></article-title>
        </div>
      </div>
      <div class="m-30">
        <page-com :total="currentSortCount" :singleCount= "10" @changePage="changePage"></page-com>
      </div>
      <div>
        <footer-com :color="true"></footer-com>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import ArticleTitle from '../components/ArticleTitle';
import PageCom from '../components/PageCom';
import FooterCom from '../components/Footer';
const cubic = value => Math.pow(value, 3);
const easeInOutCubic = value => value < 0.5 ? cubic(value * 2) / 2 : 1 - cubic((1 - value) * 2) / 2;
export default {
  data(){
    return {
      navList: [],
      articleList: [],
      count: {},
      isActive: 'all',
      currentSortCount: ''
    }
  },
  components:{
    ArticleTitle,
    PageCom,
    FooterCom
  },
  methods:{
    toTop() {
      // el-scrollbar 容器
      const el = this.$refs['scroll'].wrap;
      const beginTime = Date.now();
      const beginValue = el.scrollTop;
      const rAF = window.requestAnimationFrame || (func => setTimeout(func, 16));
      const frameFunc = () => {
        const progress = (Date.now() - beginTime) / 500;
        if (progress < 1) {
          el.scrollTop = beginValue * (1 - easeInOutCubic(progress));
          rAF(frameFunc);
        } else {
          el.scrollTop = 0;
        }
      };
      rAF(frameFunc);
    },
    async getCategotries(){
      const result = await this.$axios.get('/rest/Category')
      this.navList = result.data
    },
    publishArticle(){
      if(this.$store.state.user.username){
        this.$router.push('/publishArticle')
      }else{
        this.$router.push('/login')
      }
    },
    async getArticleList(page){
      const model = await this.$axios.get(`/rest/articleList/${page - 1}`)
      this.articleList = model.data
    },
    async getCount(){
      const model = await this.$axios.get('/count')
      this.currentSortCount = model.data.articles
      this.count = model.data
    },
    async articleSort(_id, count){
      this.isActive = _id
      this.currentSortCount = count
      const model = await this.$axios.get(`/articleSort/${_id}`)
      this.articleList = model.data
      this.toTop()
    },
    changePage(page){
      this.toTop()
      this.getArticleList(page)
    }
  },
  async mounted(){
    this.getCategotries()
    this.getCount()
    this.getArticleList(1)
  }
}
</script>

<style lang="scss" scoped>
  @import '../assets/css/variables';
  .background-color{
    background: map-get($colors, 'background');
    height: calc(100vh + 2rem);
  }
  .wrap{
    width: 140rem;
    margin: 0 auto;
    padding-top: 7rem;
    transform: translate(-5vw, 0);
    min-height: calc(100vh - 23rem);
    .article-nav{
      width: 25rem;
      border-radius: 0.5rem;
      .issue-article{
        text-align: center;
        background: map-get($colors, 'purple');
        color: white;
        border-radius: 0.5rem;
      }
      .nav-list{
        margin-top: 5%;
        background: white;
        border-radius: 0.5rem;
        .active{
          background: #f2f2f2;
          font-weight: bold;
          border-radius: 0.5rem;
        }
      }
    }
    .article-list{
      width: 113rem;
    }
  }
</style>